












































































































































































import { Component, Vue } from 'vue-property-decorator'
import { deleteVisit, deleteVisitBulk, exportVisits, getVisits, getVisitsCount } from '@/api/visits'
import {
  clientsFilter,
  createFilter,
  dateFilter,
  getSortItems,
  ignoreCaseSearchField,
  personalFilter,
  prepareListFilters,
  propertiesFilter,
  searchFieldConcat, searchFieldConcatReverse,
  usersFilter
} from '@/utils/filter'
import Pagination from '@/components/Pagination/index.vue'
import ListToolbar from '@/components/ListToolbar/index.vue'
import FiltersDrawer from '@/components/Filter/FiltersDrawer.vue'
import {
  debounce,
  parseTime,
  getTranslations,
  hasPermission,
  confirmDialog,
  successMsg,
  errorMsg,
  getSubText
} from '@/utils'
import { AppModule, DeviceType } from '@/store/modules/app'

@Component({
  name: 'VisitList',
  components: {
    Pagination,
    ListToolbar,
    FiltersDrawer
  }
})

export default class extends Vue {
  private count = 0
  private total = 0
  private list = []
  private listLoading = true
  private showFilters = false
  private search = ''
  private selected = []
  private listQuery = createFilter()
  private debounceMethod = debounce(this.getList)
  private parseTime = parseTime
  private getTranslations = getTranslations
  private hasPermission = hasPermission
  private getSubText = getSubText
  private searchFields = [
    searchFieldConcat('user.'),
    searchFieldConcatReverse('user.'),
    searchFieldConcat('client.'),
    searchFieldConcatReverse('client.'),
    ignoreCaseSearchField('property.full_code')
  ]

  private filters: any = [
    clientsFilter(),
    usersFilter(),
    propertiesFilter(),
    dateFilter(),
    dateFilter({
      key: 'date',
      label: 'filters.visitDate'
    })
  ]

  created() {
    this.getList()
    if (hasPermission(['LIST_OTHERS_VISITS'])) {
      this.filters.push(personalFilter({
        key: 'owner',
        label: 'filters.personalVisits'
      }))
    }
  }

  get isMobile() {
    return AppModule.device === DeviceType.Mobile
  }

  private async deleteItem(id: string) {
    const [data] = await confirmDialog('actions.apiDelete')
    if (!data) return
    try {
      await deleteVisit({ id })
      await successMsg('actions.apiDeleteSuccess')
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private handleSelectionChange(selected = []) {
    this.selected = selected
  }

  private handleSortChange(params: any = {}) {
    if (this.listQuery?.query?.sort) {
      this.listQuery.query.sort = getSortItems(params)
    }
    this.getList()
  }

  private async deleteItemsBulk() {
    if (!this.selected.length) {
      return errorMsg('actions.selectItems')
    }
    const [data] = await confirmDialog('actions.apiDeleteBulk')
    if (!data) return
    try {
      await deleteVisitBulk(this.selected.map((item: any) => {
        return item.id
      }))
      await successMsg('actions.apiDeleteBulkSuccess')
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private prepareFilters(filters: any) {
    prepareListFilters({
      listQuery: this.listQuery,
      withDefaultSort: true,
      search: this.search,
      filters: filters,
      searchFields: this.searchFields
    })
  }

  private submitFilters(filters: any) {
    this.filters = filters
    this.restartPaging()
    this.getList()
  }

  private async filtersChanged(filters: any) {
    try {
      this.prepareFilters(filters)
      const { data } = await getVisitsCount(this.listQuery)
      this.count = data || 0
    } catch (err) {}
  }

  private redirectToEdit(id: any) {
    return this.$router.push('/visit/edit/' + id)
  }

  private handleCommand(command: string) {
    switch (command) {
      case 'download': {
        exportVisits(this.selected)
        break
      }
      case 'delete': {
        this.deleteItemsBulk()
        break
      }
      case 'filters': {
        this.count = this.total
        this.showFilters = true
        break
      }
    }
  }

  private restartPaging() {
    if (this.listQuery.query?.pagination) {
      this.listQuery.query.pagination.page = 1
    }
  }

  private async getList() {
    this.listLoading = true
    try {
      this.prepareFilters(this.filters)
      const { data } = await getVisits(this.listQuery)
      this.list = data.collection || []
      this.total = data.pagination.total_items || 0
      this.count = data.pagination.total_items || 0
    } catch (err) {}
    this.listLoading = false
  }

  onSearchChanged(search: string) {
    this.search = search
    this.restartPaging()
    this.debounceMethod()
  }
}
